@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

body
{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol'; 
  touch-action: manipulation;
}

.helpbox
{
  display: none;
}

.fox-word-page
{
  background-color: bisque;
  min-height:100vh;
  overflow: hidden;
  position: relative;
  
}

.fox-word-box
{
  background-color: pink;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  .screen
  {
    position: relative;
    flex:2;

    .fox-next-button-group
    {
      position: absolute;
      bottom: 10px;
      right:10px;
      background-color: rgba( 0, 0, 0, 0.4 );
      border-radius: 5px;
    }
    .header
    {
      display: flex;
      flex-direction: row;
      height:60px;
      justify-content:space-between;
      z-index:19;

      svg
      {
        color:white;
      }

    }

    .word-area
    {
      height: calc( 100% - 60px );
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color:white;
      overflow: hidden;

      .word
      {
        margin-bottom: 20px;
        font-size: 10vw;
        font-family: Georgia, 'Times New Roman', Times, serif;
        text-shadow: 2px 2px 0px rgba( 0, 0, 0, 0.8 );
        word-break: break-all;
        text-align: center;
        width: 100%;

        span.typebox
        {
          border:1px solid rgba( 255, 255, 255, 0.2 );
          border-radius: 5px;
          padding:6px;
          &:not(:last-child)
          {
            margin-right: 5px;
          }
        }
      }

      .explain
      {
        font-size: 16px;
        padding-left:20px;
        padding-right:20px;
        line-height: 1.8;
        margin-top: 10px;
      }

      .tips
      {
        font-size: 16px;
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;
        margin-bottom: 10px;
        span
        {
          &.letter
          {
            border-bottom: 4px solid white;
          }

          margin-right: 10px;
          
        }
      }
    }

    .mask
    {
      background-color: black;
      position: absolute;
      top:0px;
      bottom: 0px;
      left:0px;
      right:0px;
      z-index:1;
    }

    .over-mask
    {
      z-index: 100!important;
      position: absolute;
      top:0px;
      bottom: 0px;
      left:0px;
      right:0px;
    }
  }
  .keyboard
  {
    display: flex;
    flex:1;
    min-height: 36vh;
    max-height: 36vh;

    .panel
    {
      display: flex;
      background-color: #f4f4f4;
      flex:1;
      flex-direction: column;
      border-top:1px solid #ccc;
      padding-top:2px;
      .key-row
      {
        display:inline-flexbox;
        flex:8;
        padding:2px;
        .keybtn
        {
          margin:5px;
          // &.narrow
          // {
          //   margin-left:2px;
          //   margin-right:2px;
          // }
         
        }

        overflow-x: hidden;
        overflow-y: auto;

        .bp3-button
        {
          min-width: 16vw;
          height: 7vh;
          .bp3-button-text
          {
            font-size: 32px;
            user-select: none;
          }
          
          
          box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 1px 1px rgba(16,22,26,.2), 0 2px 6px rgba(16,22,26,.2);
        }
      }

      .key-row-narrow
      {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        flex:8;
        padding-left:3vw;
        padding-right:3vw;
        padding-top:2vh;
        padding-bottom:2vh;
        .line
        {
          display: flex;
          justify-content: space-evenly;
        }
        .keybtn
        {
          margin:.6vw;
        }

        overflow-x: hidden;
        overflow-y: auto;

        .bp3-button
        {
          max-width: 8vw;
          min-width: 2vw;
          height: 7vh;
          .bp3-button-text
          {
            font-size: 30px;
            user-select: none;
          }
          
          box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 1px 1px rgba(16,22,26,.2), 0 2px 6px rgba(16,22,26,.2);
        }
      }

      .action-bar
      {
        flex:1;
        max-height: 60px;
        min-height: 60px;
        background-color: #44454D;

        display: flex;
        justify-content: space-around;
        align-items: center;

        .bp3-button-text
        {
          font-size: 24px;
        }

        svg
        {
          color:white;
        }
      }



    }

  }
  
}

.fox-logo-box
{
  display: flex;
  align-items: center;
  justify-content: center;
  img
  {
    max-width: 120px;
    max-height: 120px;
    margin:20px;
  }
}

.fox-dict-page,.fox-settings-page,.fox-login-page,.fox-buy-page,.fox-std-page
{
  background-color: white;
  .header
  {
    display: flex;
    flex-direction: row;
    height:60px;
    justify-content: space-between;
    .left,.right
    {
      flex:1;
      max-width:50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .center
    {
      flex:6;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: burlywood;
      font-size: 24px;
    }
  }

  .dict-list
  {
    padding:0px;
    margin:0px;
    li
    {
      &.member
      {
        color:$blue1;
      }
      
      &.active
      {
        background-color: $blue5;
        color:white;

        svg
        { 
          color:white!important; 
        }
      }
      
      &:hover
      {
        background-color: #f4f4f4;
        color:black;
        svg
        {
          color:black!important; 
        }
      }
      cursor: pointer;
      display: flex;
      border-bottom: 1px solid #ccc;
      padding:20px;
      font-size: 20px;
      .left
      {
        display: flex;
        flex:4;

      }
      .right
      {
        display: flex;
        flex:1;
        max-width: 50px;
        justify-content: flex-end;
        svg
        {
          color:#aaa;
        }
      }
    }
  }

}

.fox-dict-page
{
  .header
  {
    position: fixed;
    z-index:19;
    width: 100%;
    background-color: rgba($color: white, $alpha: 0.95);
  }

  .dict-list
  {
    padding-top:60px;
  }
}

.fox-settings-page
{
  .header
  {
    position: fixed;
    z-index:19;
    width: 100%;
    background-color: rgba($color: white, $alpha: 0.95);
  }

  .form
  {
    padding-top:60px;
  }

  .item.row
  {
    flex-direction: column!important;
    justify-content: flex-start!important;
    .inner-hr
    {
      width: 30%;
      border-bottom: 1px solid #ddd;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    > *
    {
      width:100%;
    }
  }
}

.fox-settings-page,.fox-login-page,.fox-buy-page
{
  .form .hr
  {
    background-color: #f4f4f4;
    height: 30px;
  }
  
  .form .item
  {
    display: flex;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top:10px;
    padding-bottom:10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    .left
    {
      svg
      {
        margin-right:5px;
      }

      &.member
      {
        color:$blue1;
      }

      &.gray
      {
        svg
        {
          color:#ccc;
        }
        color:#ccc;
      }
    }



    .time
    {
      font-size: 14px;
      color:#ccc;
      margin-left: 5px;
    }

    .desp
    {
      font-size: 16px;
      color:#999;
      line-height: 1.8;
    }
    
    > *
    {
      display: flex;
      align-items: center;
    }

    
    .bp3-switch
    {
      padding-top: 10px; 
    }

  }
}

.membertable
{
  width:100%;
  margin-top: 10px;
  border-top:1px solid #eee ;
  border-bottom:1px solid #eee ;
}

.right5
{
  margin-right: 5px;
}
.nobottom
{
  > *:last-child
  {
    border-bottom: 0px!important;
  }
}

.outline
{
  &.bp3-button
  {
    border:1px solid #ccc;
  }
}

.white
{
  &.bp3-button
  {
    color:white;
    svg
    {
      color:white;
    }
  }
  &.bp3-spinner
  {
    svg
    {
      color:white;
    }
  }
}

.fox-share-page
{
  min-height: 100vh;
  background-color: #44454D;

  img.share
  {
    max-width: 100%;
    padding-top:60px;
  }
  .header
  {
    position: fixed;
    z-index:19;
    width: 100%;
    background-color: rgba($color: white, $alpha: 0.95);
  }
  
  .share-box
  {
    padding-top: 60px;
    
    .preview
    {
      position: relative;
      max-width:80wh;
      background-color: bisque;
      display: flex;
      justify-content: center;
      align-items: center;

      img.overlay
      {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: auto;
      }

      .titlebox
      {
        position: absolute;
        text-shadow: 2px 2px 0px rgba( 0, 0, 0, 0.8 );
        word-break: break-all;
        text-align: center;
        color:white;
          
        .desp
        {
          margin-top: 20px;
          text-shadow: 1px 1px 1px rgba( 0, 0, 0, 1 );
          font-size: 18px;
          line-height: 2;

          .qrcode
          {
            margin-top: 20px;
          }

        }
        .title
        {
          position: relative;
          font-size: 10vw;
          font-family: Georgia, 'Times New Roman', Times, serif;
          
          span.typebox
          {
            border:1px solid rgba( 255, 255, 255, 0.5 );
            border-radius: 5px;
            padding:6px;
            &:not(:last-child)
            {
              margin-right: 5px;
            }
          }

        }
      }
      
      
    }
    .cover
    {
      max-width: 100%;
    }
  }
  .actionbar
  {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    // background-color: #eee;
  }
}

.fox-loading-page
{
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;

  .desp
  {
    margin-top: 20px;
    color:#ccc;
  }
}

.fox-guide-page
{
  .choosebox
  {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    // background-color: bisque;
    margin-left:auto;
    margin-right:auto;

    .title
    {
      font-size: 18px;
      line-height: 1.8;
    }
    .actionbar
    {
      display: flex;
      justify-content: center;
      > *:not(:last-child)
      {
        margin-right: 10px;
      }
    }
    .image
    {
      text-align: center;
      padding:20px;
    }
  }
}

.fox-fav-page
{
  .header
  {
    position: fixed;
    z-index:19;
    width: 100%;
    background-color: rgba($color: white, $alpha: 0.95);
  }

  .fav-images
  {
    padding-top: 60px;
    
    .item
    {
      position: relative;
      .float-button-group
      {
        position: absolute;
        bottom: 10px;
        right:10px;
        border-radius: 5px;
        background-color: rgba( 0, 0, 0, 0.4 );
      }
      img
      {
        max-width: 100%;
      }
    }
  }

  .loadbtn
  {
    margin-top:20px;
    margin-bottom:20px;
    display: flex;
    justify-content: center;
  }
}

.vcenter
{
  display: flex;
  align-items: center;
}

.xcenter
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.underline
{
  border-bottom: 1px solid #ccc;
}

.fox-login-page
{
  .qrbox
  {
    padding:20px;
    text-align: center;
    max-width:300px;
    margin-left: auto;
    margin-right: auto;
    .footer
    {
      color:#999;
      line-height: 1.8;
    }
    .image
    {
      img
      {
        max-width: 100%;
      }
     
    }
    .actionbar
    {
      > *
      {
        margin-top: 20px;
      }
    }
  }
}

.fox-buy-page
{
  .fox-member-price
  {
    margin: 0px;
    padding:0px;
    display: flex;
    flex-direction: column;
    > li
    {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding:20px;
      border-bottom: 1px solid #eee;
      .right
      {
        display: flex;
        align-items: center;
      }
    }
    .price
    {
      font-size: 28px;
      font-family: Georgia, 'Times New Roman', Times, serif;
      color:$blue1;
      .full
      {
        font-size: 24px;
        color:#aaa;
        text-decoration: line-through;
        margin-left: 10px;
      }
      margin-bottom: 5px;
    }
  }
}


@media only screen and (min-width: 600px) 
{
  html,body
  {
    background-color: black;
    // background-image: url("/bg.jpeg");
  }
  
  .fox-dict-page,.fox-settings-page,.fox-login-page,.fox-buy-page,.fox-std-page
  {
    max-width:600px;
    // overflow-x: hidden;
    margin: auto;
    // box-shadow: 0px 0px 8px #333 ;
    border-left:1px solid #ccc;
    border-right:1px solid #ccc;
    min-height:100vh;
    
    &:not(.fox-share-page)
    {
      background-color: white;
    }
    


    .header
    {
      width:100%;
      max-width: 600px;
    }
  }

  .fox-word-page
  {
    max-width:600px;
    margin: auto;
    // box-shadow: 0px 0px 8px #333 ;

    // .keyboard
    // {
    //   display: none;
    // }
  }

  .helpbox
  {
    display: block;
    position: absolute;
    bottom:10px;
    right:10px;
    text-align: right;
    color:white;
    text-shadow: 1px 1px 1px rgba( 0, 0, 0, 1 );
    .light
    {
      margin-top: 30px;
      color:#eee;
    }
    z-index:19;
  }

  .key-row
  {
    padding-top:2vh;
    padding-bottom:2vh;

    .bp3-button
    {
      min-width: 100px!important;
    }  
  }

  .key-row-narrow
  {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    flex:8;
    padding-left:10px;
    padding-right:10px;
    padding-top:2vh;
    padding-bottom:2vh;
    .line
    {
      display: flex;
      justify-content: space-evenly;
    }
    .keybtn
    {
      margin:5px!important;
    }

    overflow-x: hidden;
    overflow-y: auto;

    .bp3-button
    {
      max-width: 45px!important;
      min-width: 30px!important;
      height: 7vh;
      
    }
  }
}

.fox-toaster
{
  z-index:99;
}

.piano
{
  // background-color: black;
  position: absolute;
  top:0px;
  bottom: 0px;
  left:0px;
  right:0px;
  z-index:1;
  display: flex;
  .item
  {
    flex:1;
    display: flex;
    // margin-left:1px;
    &.black1
    {
      background-color: rgba( 0, 0, 0, 0.5 );
    }
    &.black2
    {
      background-color: rgba( 0, 0, 0, 0.8 );
    }
    &.black
    {
      background-color: rgba( 0, 0, 0, 0.98 );
    }
    &.white
    {
      background-color: rgba( 0, 0, 0, 0 );
    }
  }
}



  